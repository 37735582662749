import { Prop, Component, Vue } from 'vue-property-decorator';
import { EventBus } from '@/utilities/eventBus/EventBus';
import ValidationModel from '@/models/Components/Inputs/ValidationModel';
import LooseObject from '@/models/Objects/LooseObject';
import { getFormattedString } from '@/utilities/mutate';
import router from '@/router';

@Component
export default class Widget extends Vue {
    @Prop() public props!: LooseObject;

    private formattedContent: string = getFormattedString(this.props, this.props.widget);

    protected created() {
        EventBus.$on('requestValue', this.submitValue);
    }

    protected beforeDestroy() {
        EventBus.$off('requestValue', this.submitValue);
    }

    protected mounted() {
        this.checkForEditValue();
        if (this.props.defaultEditValue !== null) {
            if (document.getElementById(this.props.defaultEditValue)) {
                document.getElementById(this.props.defaultEditValue)!.className += ' active';
            }
            this.props.value = this.props.defaultEditValue;
        }
    }

    private setInitialObjectValues() {
        this.$emit('update:value', this.props.value);
    }

    private setValue(value: string) {

        const btns = document.getElementsByClassName('ant-card');
        for (const btn of btns) {
            btn.className = btn.className.replace(' active', '');
        }
        document.getElementById(value)!.className += ' active';
        this.props.value = value;
    }

    private isValueValid() {
        if (this.props.required && !this.props.value) {
            return false;
        }
        return true;
    }

    private submitValue() {
        if (this.isValueValid()) {
            EventBus.$emit(
                'submitValue',
                this.props.elementId,
                this.props.value,
                this.props.label,
                getFormattedString(this.props, this.props.value),
            );
        }
    }

    private updateInputValues(inputValue: string) {
        this.props.value = inputValue;
        this.$emit('update:value', inputValue);
    }

    private checkForEditValue() {
        if (this.props.defaultEditValue !== null) {
            this.updateInputValues(this.props.defaultEditValue.toString());
        } else {
            this.updateInputValues('');
        }
    }
}
