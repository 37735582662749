import { Prop, Component, Vue, Ref } from 'vue-property-decorator';
import { EventBus } from '@/utilities/eventBus/EventBus';
import LooseObject from '@/models/Objects/LooseObject';
import MenuModel from '@/models/Objects/MenuModel';
import MyMenuItem from '@/models/Objects/MenuItem';
import store from '@/store';
import Home from '@/views/Home/Home';

@Component
export default class TopMenu extends Vue {
    @Ref('Menu') private Menu?: HTMLElement;
    private collapsed: boolean = false;
    private home = new Home();
    @Prop() private topMenu?: MenuModel;
    private openKeys: string[] = [];

    protected toggleCollapsed() {
        this.collapsed = !this.collapsed;
    }


    protected mounted() {
        EventBus.$on('update-menu-top', (responseMenu: any) => {
            this.topMenu = responseMenu;
            if (this.openKeys.length === 0) {
                if (this.topMenu) {
                    for (let i = 0; i < this.topMenu.menuItems.length; i++) {
                        if (this.topMenu?.menuItems[i].title) {
                            this.openKeys.push(this.topMenu?.menuItems[i].title);
                        }
                    }
                }
            }
        });
    }

    private resolveTrigger(targetKey: string) {
        if (this.topMenu) {
            for (let i = 0; i < this.topMenu.menuItems.length; i++) {
                if (this.topMenu?.menuItems[i].title === targetKey) {
                    store.commit('updateTrigger', this.topMenu?.menuItems[i].trigger);
                    EventBus.$emit('resolveTrigger', this.topMenu?.menuItems[i].reInitialise);
                    break;
                }
            }
        }

    }

    private updateKeys(key: string) {
        if (this.openKeys.includes(key)) {
            delete (this.openKeys[this.openKeys.indexOf(key)]);
        } else {
            this.openKeys.push(key);
        }
    }
}
