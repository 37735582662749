import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import axios from 'axios';
import { MsalPlugin, MsalPluginOptions } from './utilities/msalPlugin/msalPlugin';

Vue.config.productionTip = false;
Vue.use(Antd);
const loginVar = process.env.VUE_APP_REQUIRES_LOGIN;

const msalOptions: MsalPluginOptions = {
  requiresLogin: loginVar === 'true',
  clientId: '' + process.env.VUE_APP_AZURE_AD_CLIENT_ID,
  loginAuthority:  '' + process.env.VUE_APP_AZURE_AD_LOGIN_AUTHORITY,
  knownAuthority: '' + process.env.VUE_APP_AZURE_AD_KNOWN_AUTHORITY,
  redirectUri: '' + process.env.VUE_APP_AZURE_AD_REDIRECT_URI,
  scope: '' + process.env.VUE_APP_AZURE_AD_SCOPE,
};

Vue.use(new MsalPlugin(), msalOptions);

Vue.use({
  install(vue, opts = {}) {
    vue.prototype.$axiosClient = axios.create({});
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
